<template>
  <base-material-card icon="mdi-clipboard-text" :title="$t('menu.promo.list')" class="px-5 py-3">
    <div class="d-flex">
      <base-dropdown-filter
        :items="activeFilters"
        @changeFilter="changeActiveFilter"
        style="flex: 0 0 75%; max-width: 75%"
        label="Status Aktif"
      >
      </base-dropdown-filter>
      <base-add-button
        :permissions="permissions"
        :basePath="basePath"
        :title="$t('promo.btn.add')"
        style="flex: 0 0 25%; max-width: 25%"
      ></base-add-button>
    </div>
    <div class="mt-2">{{ $t('promo.total') }}: {{ meta.total }}</div>
    <v-simple-table v-if="items && items.length > 0">
      <thead>
        <tr>
          <th class="text-center primary--text">
            {{ $t('general.actions') }}
          </th>
          <th class="primary--text">No.</th>
          <th class="primary--text">
            {{ $t('promo.code') }}
          </th>
          <th class="primary--text">
            {{ $t('promo.salesName') }}
          </th>
          <th class="primary--text">
            {{ $t('promo.discountAmount') }}
          </th>
          <th class="primary--text">
            {{ $t('promo.discountPercentage') }}
          </th>
          <th class="primary--text">
            {{ $t('general.status') }}
          </th>
          <th class="primary--text">
            {{ $t('general.updatedBy') }}
          </th>
        </tr>
      </thead>

      <tbody>
        <tr v-for="(item, index) in items" :key="`agent-banner-${index}`">
          <td>
            <div class="d-flex justify-center align-center">
              <base-edit-button
                :permissions="permissions"
                :basePath="basePath"
                :id="item.uuid"
              ></base-edit-button>
              <base-delete-button
                :permissions="permissions"
                :id="item.uuid"
                :showIcon="true"
                @deleteAction="deleteAction"
              ></base-delete-button>
            </div>
          </td>
          <td>{{ index + 1 }}</td>
          <td>{{ item.promo_code }}</td>
          <td>{{ item.sales_name }}</td>
          <td>{{ currencyFormat(item.discount_amount) }}</td>
          <td>{{ item.discount_percentage }}</td>
          <td>{{ item.is_active ? $t('general.active') : $t('general.inactive') }}</td>
          <td>{{ item.updated_by }}</td>
        </tr>
      </tbody>
    </v-simple-table>
    <pagination
      :push-state="false"
      :meta="meta"
      @changePage="changePage"
      :maxVisibleButtons="5"
      v-if="meta"
    />
  </base-material-card>
</template>

<script>
import { mapState } from 'vuex';
import HelperMixin from '@/mixins/helpers';
import { format } from 'date-fns';
const Pagination = () => import('@/components/utils/fractal-pagination');

export default {
  mixins: [HelperMixin],
  props: ['items', 'meta', 'permissions', 'activeFilters'],
  components: {
    Pagination,
  },
  data() {
    return {
      format,
    };
  },
  computed: {
    ...mapState({
      basePath: (state) => state.promo.basePath,
    }),
  },
  methods: {
    changePage(page) {
      this.$emit('changePage', page);
    },
    changeActiveFilter(val) {
      this.$emit('changeActiveFilter', val);
    },
    async deleteAction(uuid) {
      try {
        await this.$store.dispatch('promo/delete', uuid);
        this.$swal(
          this.$i18n.t('general.success'),
          this.$i18n.t('promo.delete.successMsg'),
          'success',
        );
        let query = this.$route.query;
        this.$store.commit('promo/RESET_DEFAULT');
        await this.$store.dispatch('promo/getInitData', query);
        // eslint-disable-next-line no-empty
      } catch (e) {}
    },
  },
};
</script>
